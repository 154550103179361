header {
    height: 100vh;
    padding-top: 7rem;
    overflow: hidden;
    font-size: large;
    /* background-size: cover;
    background-position: top;
    position: relative; */
    

}

.header__container {
    text-align: center;
    height: 100%;
    position: relative;
    /* display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center; */
    
    
}

.heading-primary--main {
    font-size: 3.5rem;
    font-weight: 700;
    background-image: linear-gradient(to right, var(--color-primary-light), var(--color-primary));
    -webkit-background-clip: text;
    color: transparent;
    letter-spacing: 2rem;
    backface-visibility: hidden;
    animation-name: moveInLeft;
    animation-duration: 1s;
    animation-timing-function: ease-out;
}

.heading-primary--sub {
    font-size: 2rem;
    letter-spacing: 1rem;
    
}

/* *****Image****** */
.leaf {
    width: 27rem;
    height: 30rem;
    position: absolute;
    left: calc(50% - 12rem);
    margin-top: 4rem;
}


/* **** Call to action buttons**** */

.cta {
    margin-top: 2.5rem;
    display: flex;
    gap: 1.2rem;
    justify-content: center;
}

/* **** Header Socials **** */

.header__socials {
    display: flex;
    flex-direction: column;
    align-items: center;
    gap: 0.8rem;
    position: absolute;
    left: 0;
    bottom: 10rem;
}

.header__socials::after {
    content: '';
    width: 1px;
    height: 2rem;
    background: var(--color-primary)
}


/* *****Scroll Down******** */

.scroll__down {
    position: absolute;
    right: -2.3rem;
    bottom: 15rem;
    transform: rotate(90deg);
    font-weight: 300;
    font-size: 0.9rem;

}


/* ******Media Queries (Medium Devices)********  */

@media screen and (max-width: 1024px) {
    header {
        height: 68vh;
    }

    .heading-primary--main {
        font-size: 3rem;
        letter-spacing: 1rem;
    }
    
    .heading-primary--sub {
        font-size: 1.5rem;
        letter-spacing: .5rem;
    }

}

/* ******Media Queries (Small Devices)********  */

@media screen and (max-width: 600px) {
    header {
        height: 100vh;
    }

    .heading-primary--main {
        font-size: 2rem;
    }
    
    .heading-primary--sub {
        font-size: 1rem;
    }

    .header__socials,
    .scroll__down {
        display: none;
    }
}