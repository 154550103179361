.services__container {
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
    gap: 4rem;
}

.service {
    background: var(--color-bg-variant);
    border-radius: 0 0 2rem 2rem;
    border: 1px solid var(--color-primary);
    height: fit-content;
    transition: var(--transition);
    max-width: 1400px;
}

.service:hover {
    background: transparent;
    border-color: var(--color-primary-variant);
    cursor: default;
}

.service__head {
    background: var(--color-primary);
    padding: 2rem;
    border-radius: 0 0 2rem 2rem;
    box-shadow: 0 2rem 1rem rgba(0, 0, 0, 0.1);
}

.service__head h3 {
    color: var(--color-bg);
    font-size: 2rem;
    text-align: center;
}

.service__list {
    display: flex;
    flex-direction: column;
    align-items: center;
    padding: 2rem;
}

.service__list p {
    display: flex;
    gap: 1rem;
    padding: 1rem;
    margin-bottom: 00.8rem;
    font-size: 1.5rem;
    font-weight: 100;
    text-align: center;
}

.service__list img {
    border-radius: 0 0 2rem 2rem;
    max-width: 800px;
}

/*slideshow */

.each-slide-effect > div {
    display: flex;
    align-items: center;
    justify-content: center;
    background-size: cover;
    height: 350px;
}
  
.each-slide-effect span {
    padding: 20px;
    font-size: 20px;
    background: #efefef;
    text-align: center;
}




/* ******Media Queries (Medium Devices)********  */

@media screen and (max-width: 1024px) {

    .services__container {
        flex-direction: column;
        gap: 2rem;
    }

    .service__list p {
        font-size: 1rem;
    }

    .service {
        height: auto;
    }

}

/* ******Media Queries (Small Devices)********  */

@media screen and (max-width: 600px) {

    .services__container {
        gap: 1.5rem;
    }

    .service p {
        padding-left: 0;
        padding-right: 0;
    }

}