.container.contact__container {
    width: 58%;
    display: grid;
    grid-template-columns: 30% 58%;
    gap: 12%;
}

.contact__options {
    display: flex;
    flex-direction: column;
    gap:1.2rem;
}

.contact__option {
    background: var(--color-bg-variant);
    padding: 1.2rem;
    border-radius: 1.2rem;
    text-align: center;
    border: 1px solid transparent;
    transition: var(--transition);
}

.contact__option:hover {
    background-color: transparent;
    border-color: var(--color-primary-variant);
}

.contact__option-icon {
    font-size: 1.5rem;
    margin-bottom: 0.5rem;
}

.contact__option a {
    margin-top: 0.7rem;
    display: inline-block;
    font-size: 0.8rem;
}

.hours{
    text-align: center;
    font-size: 1rem;
    padding: 2.4rem 5rem;
    border-radius: 2rem;
    border: 1px solid transparent;
    background: transparent;
    border-color: var(--color-primary-variant);
    cursor: default;
    
}

.hours__header {
    color: var(--color-primary);
    display: flex;
    gap: .5rem;
    justify-content: center;
}

/* ************Form**************** */

form {
    display: flex;
    flex-direction: column;
    gap: 1.2rem;
}

input, textarea {
    width: 100%;
    padding: 1.5rem;
    border-radius: 0.5rem;
    background: transparent;
    border: 2px solid var(--color-primary-variant);
    resize: none;
    color: var(--color-white);
}

.footer__socials {
    display: flex;
    justify-content: center;
    gap: 1rem;
    margin-bottom: 4rem;
}

.footer__socials a {
    background: var(--color-bg);
    color: var(--color-white);
    padding: 0.8rem;
    border-radius: 0.7rem;
    margin-bottom: 4rem;
}

.contact__socials {
    display: flex;
    justify-content: center;
    padding-top: 2rem;
    gap: 1rem;
    font-size: 4rem;
}


/* ******Media Queries (Medium Devices)********  */

@media screen and (max-width: 1024px) {
    .container.contact__container {
        grid-template-columns: 1fr;
        gap: 2rem;
    }

    .hours {
        padding: 1rem 1rem;
    }


}

/* ******Media Queries (Small Devices)********  */

@media screen and (max-width: 600px) {
    .container.contact__container {
        width: var(--container-width-sm);
    }

    .hours {
        padding: 2rem 2rem;
    }
}