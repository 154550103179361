.treatments__container {
    display: grid;
    grid-template-columns: 1fr 1fr;
    gap: 2rem;
}

.treatments__container > div {
    background: var(--color-bg-variant);
    padding: 2.4rem 5rem;
    border-radius: 2rem;
    border: 1px solid transparent;
    transition: var(--transition);
}

.treatments__container > div:hover {
    background: transparent;
    border-color: var(--color-primary-variant);
    cursor: default;
}

/* .treatments__container > div:last-child:hover {
    background: transparent;
    border-color: var(--color-primary);
    cursor: default;
} */

.treatments__container > div h3 {
    font-size: 2.5rem;
    text-align: center;
    margin-bottom: 2rem;
    color: var(--color-primary);
}

.treatments__laser {
    align-items: center;
    justify-content: center;
}

.treatments__content {
    display: grid;
    grid-template-columns: 1fr 1fr 1fr;
    gap: 1rem;
}

.treatments__book {
    display: flex;
    justify-content: center;
    gap: 1rem;
}

.treatments__details {
    display: flex;
    justify-content:center;
    flex-direction: row;
    gap: 1rem;
    margin-left: 1rem;
    margin-bottom: 2rem;
}

.treatments__details-icon {
    margin-top: 6px;
    color: var(--color-primary);
}
/* #promotions {
    color: var(--color-primary-variant);
} */
/* ******Media Queries (Medium Devices)********  */

@media screen and (max-width: 1024px) {
    .treatments__container {
        grid-template-columns: 1fr;
    }

    .treatments__container > div {
        width: 80%;
        padding: 2rem;
        margin: 0 auto;
    }
    .treatments__content {
        padding: 1rem;
        text-align: center;
    }


    
}

/* ******Media Queries (Small Devices)********  */

@media screen and (max-width: 600px) {

    .treatments__container {
        gap: 1rem;
    }

    .treatments__container > div {
        width: 100%;
        padding: 2rem 1rem;
        margin: 0 auto;
    }
    .treatments__content {
        padding: 1rem;
    }
}