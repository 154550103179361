.experience__container {
    text-align: center;
    font-size: 1rem;
    padding: 2.4rem 5rem;
    border-radius: 2rem;
    border: 1px solid transparent;
    background: transparent;
    border-color: var(--color-primary-variant);
    cursor: default;
    
    
}

.experience__container p {
    display: flex;
    gap: 1rem;
    padding: 1rem;
    margin-bottom: 00.8rem;
    font-size: 1.5rem;
    font-weight: 100;
    text-align: center;
    color: var(--color-primary-light);
}

/* ******Media Queries (Medium Devices)********  */

@media screen and (max-width: 1024px) {

    .experience__container {
        flex-direction: column;
        gap: 2rem;
    }

    .experience__container p {
        font-size: 1rem;
    }


}

/* ******Media Queries (Small Devices)********  */

@media screen and (max-width: 600px) {

    .experience__container {
        gap: 1.5rem;
        margin: none;
        padding: 0;
    }

}